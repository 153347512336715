import React, { useRef, useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Canvas, useFrame, useThree, useLoader } from 'react-three-fiber'
import {TextureLoader} from 'three'
import {ObjectMesh} from './ObjectMesh'
import {Controls} from './Controls'
import {Effects} from './Effects'
import {TextureBillboard} from './TextureBillboard'
import {DataBillboard} from './DataBillboard'
import {setUniformsFromRenderParams} from './AnimationUtils'

const backgroundPath = process.env.PUBLIC_URL + '/assets/grass.jpg';

function Scene(props){
  const {sceneWidth, onNewFrame, animation, customObj,
     uniforms, bgUniforms, initIterativeProcess} = props;
  const width=sceneWidth, height=sceneWidth;
  const [frame, setFrame] = useState(0);

  let renderParams = null;
  if(animation) {
    renderParams = animation.getRenderParams();
    setUniformsFromRenderParams(uniforms, bgUniforms, renderParams);
  }

  const { gl, scene, camera, size } = useThree()

  if(frame == 0) {
    camera.fov = 35;
    camera.position.set(0, 0, .85);
    camera.updateProjectionMatrix();
  }

  const glc = gl.domElement.getContext("webgl2");
  const pixels = useMemo (() => new Uint8Array(width * height * 4), [width, height]);

  useFrame(({ gl, scene, camera }) => {
    setFrame(frame+1);
    glc.clearColor(0.5,0.5,0.5, 1.0);
    glc.clear(gl.COLOR_BUFFER_BIT);
    gl.render(scene, camera)
  }, 1);

  useFrame(({ gl, scene, camera }) => {
    glc.readPixels(0, 0, width, height, glc.RGBA, glc.UNSIGNED_BYTE, pixels);
    const pixelCopy = new Uint8Array(pixels);
    if(onNewFrame) {
      onNewFrame(pixelCopy, width, height);
    } else {
      console.log("no onNewFrame callback passed!");
    }
    if(initIterativeProcess) {
      initIterativeProcess(pixelCopy, width, height);
    }
  }, 3);

  return(<group>
  <React.Suspense fallback={<mesh />}>
    <TextureBillboard uniforms={bgUniforms}
    texturePath={backgroundPath}/>
  </React.Suspense>
  <React.Suspense fallback={<mesh />}>
    <ObjectMesh customObj={customObj} uniforms={uniforms}/>
  </React.Suspense>
  {/*<DataBillboard imageData={overlayImage} uniforms={{alpha:0.1, lod:0.0}} />*/  }
  </group>)
}

export function SceneCanvas(props) {
  const {sceneWidth, uniforms, effectParams, overlayImage} = props;
  if('alpha' in uniforms) {
    effectParams.alpha = uniforms['alpha'];
  }
  return (
    <div style={{position:'absolute', zIndex:0}}>
    <Canvas style={{width: sceneWidth, height: sceneWidth, background: "black" }} gl2={true}>
      <ambientLight intensity={0.5}/>
      <pointLight position={[1, 0.4, -1]} intensity={.7}/>
      <pointLight position={[-1, 0.4, 1]} intensity={.7}/>
      <pointLight position={[0, 0, 2]} intensity={.3}/>
      <Controls />
      <Scene {...props}/>
      
      <Effects effectParams={effectParams} overlayImageData={overlayImage}/>
    </Canvas>
    </div>
  )
}
