import React, {useState, useRef} from 'react';
import {Button, Dialog, DialogActions, DialogContent,
    DialogTitle,FormControlLabel, Checkbox,
    FormControl, MenuItem, Select, InputLabel} from '@material-ui/core';
import {CustomSlider} from '../ParamControls/CustomSlider'
import {tuningCurveParams} from '../SceneComponents/AnimationUtils'

function getTCPArray() {
    let outArray = [];
    Object.entries(tuningCurveParams).forEach( entry => {
        outArray.push(entry);
    });
    return outArray;
}

function getAxisSelect(axisLetter, currentVal, onChange) {
    return(
    <FormControl style={{minWidth: '100%'}}>
    <InputLabel id="demo-simple-select-label">{axisLetter+"-axis parameter"}</InputLabel>
    <Select
        width='100%'
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={currentVal}
      onChange={onChange}
    >
        {
            getTCPArray().map(([key, val]) => {
                return <MenuItem value={val}>{val}</MenuItem>
            })
        }
    </Select>
  </FormControl>)
}

const getMinMax = (paramName) => {
    if(paramName.includes("Camera")) {
        return [-180, 180];
    } else if(paramName.includes("Background Blur")) {
        return [0, 10.0];
    } else {
        return [0, 1.0];
    }
}

export function TuningCurveDialog(props) {
const {open, handleClose} = props;
const [xAxisParam, setXAxisParam] = useState(tuningCurveParams.CAMERA_YAW);
const [xParamRange, setXParamRange] = useState([-90, 90]);
const [yAxisParam, setYAxisParam] = useState(tuningCurveParams.SHADING_TEXTURE_INFLUENCE);
const [yParamRange, setYParamRange] = useState([0, 1]);
const [resolution, setResolution] = useState(10);
const [saveData, setSaveData] = useState(false);
const [saveThumbPlots, setSaveThumbPlots] = useState(false);
const [rawImages, setRawImages] = useState(false);

const [xMin, xMax] = getMinMax(xAxisParam);
const [yMin, yMax] = getMinMax(yAxisParam);

const yEnabled = 0;

  return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Set tuning curve parameters:</DialogTitle>
        <DialogContent>
            {getAxisSelect("X", xAxisParam, (evt) => {
                setXAxisParam(evt.target.value);
                setXParamRange(getMinMax(evt.target.value));
            })}
            <CustomSlider 
            labelText="X-axis parameter range:"
            valueLabelDisplay={"auto"}
            step={.01}
            min={xMin}
            max={xMax}
            value={xParamRange}
            onChange={(evt, value) => {
                setXParamRange(value);
            }}/>
            {getAxisSelect("Y", yAxisParam, (evt) => {
                setYAxisParam(evt.target.value);
                setYParamRange(getMinMax(evt.target.value));
            })}
            <CustomSlider 
            labelText="Y-axis parameter range:"
            valueLabelDisplay={"auto"}
            step={.01}
            min={yMin}
            max={yMax}
            value={yParamRange}
            onChange={(evt, value) => {
                setYParamRange(value);
            }}/>
            <CustomSlider 
            labelText={"Resolution: "+resolution+"x"+resolution}
            valueLabelDisplay={"auto"}
            step={1}
            min={2}
            max={64}
            value={resolution}
            onChange={(evt, value) => {
                setResolution(value);
            }}/>
            <FormControlLabel
                control={
                <Checkbox
                    checked={saveData}
                    onChange={(evt, val) => {
                        setSaveData(!saveData);
                    }}
                    value={saveData}
                />
                }
                label="Save data"
            />
            <FormControlLabel
                disabled={!saveData}
                control={
                <Checkbox
                    checked={saveThumbPlots}
                    onChange={(evt, val) => {
                        setSaveThumbPlots(!saveThumbPlots);
                    }}
                    value={saveThumbPlots}
                />
                }
                label="Save 2d plots"
            />
            <FormControlLabel
                disabled={!saveData}
                control={
                <Checkbox
                    checked={rawImages}
                    onChange={(evt, val) => {
                        setRawImages(!rawImages);
                    }}
                    value={rawImages}
                />
                }
                label="Save raw images"
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" onClick={() => {
              handleClose();
              }}>
            Cancel
          </Button>
          <Button onClick={() => {
              const xAxis = {
                param: xAxisParam,
                rangeMin: xParamRange[0],
                rangeMax: xParamRange[1]
              }
              const yAxis = {
                param: yAxisParam,
                rangeMin: yParamRange[0],
                rangeMax: yParamRange[1]
              }
              let params = {
                    resolution: resolution,
                    saveData: saveData,
                    saveTCThumbs: saveThumbPlots && saveData,
                    rawImages: rawImages && saveData
                }
                if(!(xAxisParam == tuningCurveParams.NO_CHANGE)) {
                    params.xAxis = xAxis;
                }
                if(!(yAxisParam == tuningCurveParams.NO_CHANGE)) {
                    params.yAxis = yAxis;
                }
              handleClose(params);}} color="primary">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
  );
}
