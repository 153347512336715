
function tanh(x){
    return 1 - 2/(Math.exp(2*x)+1);
}

export function getTestLayerActivations() {
    const testLayerActivatons = [];
    for(let i=0; i<500; i++) {
        testLayerActivatons.push(tanh((i-250)/100));
    }
    return {data: testLayerActivatons};
}

