import React, { useState } from 'react'
import { Button, FormControlLabel, Checkbox } from '@material-ui/core'
import { FormDialog } from './SceneComponents/ObjLoadingDialog'
import { TuningCurveDialog } from './TuningCurveComponents/TuningCurveDialog'
import {ModelSelectionDialog} from './ModelComponents/ModelSelectionDialog'

export function Sidebar(props) {
    const [objDialogOpen, setObjDialogOpen] = useState(false);
    const [modelDialogOpen, setModelDialogOpen] = useState(false);
    const [tuningCurveDialogOpen, setTuningCurveDialogOpen] = useState(false);
    const { width, onCustomObjLoaded, onStartTCAnimation,
        showTuningCurves, setShowTuningCurves,
        showSortedNeurons, setShowSortedNeurons,
        compareModels, setCompareModels,
        modelDirs, setModelDirs
     } = props;

    return (<div className="column padding"
        style={{ width: width, borderRight: "1px solid gray" }}>
        <div className="row button-container"><Button className="full-width" variant="contained" color="primary" onClick={() => {
            setObjDialogOpen(true);
        }}
        >Open 3d object</Button></div>
        {/*<div className="row button-container"><Button className="full-width" variant="contained" color="primary"
        >Open CNN</Button></div>*/}
        <div className="row button-container"><Button className="full-width" variant="contained" color="primary" onClick={() => {
            setTuningCurveDialogOpen(true);
        }}>Tuning curve editor</Button></div>
        <div className="row button-container"><Button className="full-width" variant="contained" color="primary" onClick={() => {
            setModelDialogOpen(true);
        }}>Set models</Button></div>
        <div className="row">
        <FormControlLabel
            control={
            <Checkbox
                checked={compareModels}
                onChange={(evt, val) => {
                    setCompareModels(!compareModels);
                }}
                value={compareModels}
            />
            }
            label="Compare models"
        />
        </div>
        <div className="row">
        <FormControlLabel
            control={
            <Checkbox
                checked={showSortedNeurons}
                onChange={(evt, val) => {
                    setShowSortedNeurons(!showSortedNeurons);
                }}
                value={showSortedNeurons}
            />
            }
            label="Top neurons"
        />
        </div>
        <div className="row">
        <FormControlLabel
            control={
            <Checkbox
                checked={showTuningCurves}
                onChange={(evt, val) => {
                    setShowTuningCurves(!showTuningCurves);
                }}
                value={showTuningCurves}
            />
            }
            label="Tuning curves"
        />
        </div>

        <FormDialog open={objDialogOpen} handleClose={(ret) => {
            setObjDialogOpen(false);
            if (ret) {
                const { objFile, diffTexFile, alphaTexFile } = ret;
                onCustomObjLoaded({ objFile: objFile, diffTexFile: diffTexFile, alphaTexFile: alphaTexFile });
            }
        }} />
        <TuningCurveDialog open={tuningCurveDialogOpen} handleClose={(ret) => {
            setTuningCurveDialogOpen(false);
            onStartTCAnimation(ret);
        }} />
        <ModelSelectionDialog modelDirs={modelDirs} open={modelDialogOpen} handleClose={(ret) => {
            setModelDialogOpen(false);
            let updateModels = false;
            const newModelDirs = {...modelDirs}
            Object.entries(ret).forEach(([key, value]) => {
                if (value !== newModelDirs[key]) {
                    newModelDirs[key] = value;
                    updateModels = true;
                }
            });
            if(updateModels) {
                setModelDirs(newModelDirs);
            }
        }} />
    </div>);
}