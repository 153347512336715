import {tuningCurveParams} from './AnimationUtils'
import {setRenderParam} from './AnimationUtils'

export const checkAxisParams = (params) => {
    ['param', 'rangeMin', 'rangeMax'].forEach((key) => {
        if(!(key in params)) {
            return false;
        }
    });
    return true;
}

export class Animation {
    constructor(xAxis, callback, resolution=10) {
        if(!xAxis) {
            throw "Need to specify xAxis!"
        }else if(!checkAxisParams(xAxis)) {
            throw "Invalid axis params!";
        }
        this.xAxis = xAxis;
        this.resolution = resolution;
        this.frame = 0;
        this.callback = callback;
    }

    hasEnded() {
        return this.frame === this.maxFrames();
    }

    maxFrames() {
        return this.resolution;
    }

    advanceFrame() {
        this.frame += 1;
    }

    getRenderParams(){
        const frame = this.frame;
        const x = frame;
      
        const xRange = this.xAxis.rangeMax - this.xAxis.rangeMin;
        const xVal = xRange / (this.resolution-1.0) * x + this.xAxis.rangeMin;
      
        let renderParams = {};
        setRenderParam(renderParams, this.xAxis.param, xVal);
        return renderParams;
      }
}