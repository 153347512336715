import {writeImage, writeData, downloadZip, resetZip, writeTuningCurve}
from './TuningCurveFileWriter'
import {resizeRGBAImg} from '../Util/RenderingUtil'
import {TuningCurvePlot} from './TuningCurvePlot'
import {TuningCurveResult} from './TuningCurveResult'

export class TuningCurveResult2D extends TuningCurveResult {
    constructor(animation, resolution, activationParams, neurons, canvasHeight) {
        super(animation, resolution, activationParams, neurons);
        this.animationParams = {
            "xAxis": animation.xAxis,
            "yAxis": animation.yAxis,
        }
        this.tuningCurvePlot = new TuningCurvePlot(resolution, neurons, canvasHeight);
    }

    clearCanvas(){
        this.tuningCurvePlot.clearCanvas();
    }

    resetZip(){
        resetZip();
    }

    getImageData() {
        return this.tuningCurvePlot.getImageData();
    }

    persistImage(imageData, index) {
        writeImage(imageData, index);
    }

    persistTuningCurve(index) {
        const imageData = this.getImageData();
        writeTuningCurve(imageData, index);
    }

    persistInferenceData(index) {
        let outJson = {};
        outJson["activationParams"] = this.activationParams;
        outJson["animationParams"] = this.animationParams;
        this.neurons.forEach((neuron, n) => {
            const meanArray = [], varArray = [];
            outJson[neuron] = [];
            for (let y = 0; y < this.resolution; y++) {
                const lineData = [];
                const lineMean = [];
                const lineVar = [];
                for (let x = 0; x < this.resolution; x++) {
                    const i = y*this.resolution + x;
                    console.log(i)
                    const val = this.inferenceData[i].data[n];
                    const m = this.inferenceData[i].mean;
                    const v = this.inferenceData[i].variance;
                    lineData.push(val.toFixed(2));
                    lineMean.push(m.toFixed(2));
                    lineVar.push(v.toFixed(2));
                }
                outJson[neuron].push(lineData);
                meanArray.push(lineMean);
                varArray.push(lineVar);
            }
            outJson.mean = meanArray;
            outJson.variance = varArray;
        });
        const outString = JSON.stringify(outJson, null, 2);
        writeData(outString, index);
    }

    downloadZip() {
        downloadZip();
    }

    putImage(frameNumber, imageData, inferenceData) {
        this.putData(frameNumber, inferenceData);
        this.tuningCurvePlot.putImage(frameNumber, imageData, inferenceData);
    }
}