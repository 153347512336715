import React, {useState} from 'react';
import {NeuronVisCanvas} from './NeuronVisLayerView'
import {getNeuronThumbURL, archId} from '../../Model/ModelMetaInfo'

export function LayerView(props) {
    const [hoveredNeuron, setHoveredNeuron] = useState({neuron: undefined, top:0, left:0});
    const { model, index, activationParams, layerActivations, width, height} = props;
    if(index in layerActivations) {
    const barWidth = width / layerActivations[index].data.length;
    const dataArray = Array.from(layerActivations[index].data);
    const normalize = layerActivations[index].normalize ? layerActivations[index].normalize : 1;

    const neuronThumbURL = 
    getNeuronThumbURL(model, activationParams[index].layer, hoveredNeuron.neuron);

    const left = hoveredNeuron.left - (hoveredNeuron.left / width) * 64;
    const top = hoveredNeuron.top - (hoveredNeuron.top / height) * 64;
    const hoveredNeuronToolTip = (hoveredNeuron.neuron!==undefined? 
        <div className="column" style={{padding:5, background:'white', position:'absolute',
        left:left, top: top, pointerEvents:'none'}}>
            <div className="row">{hoveredNeuron.neuron}</div>
            <div className="row"><img width={64} height={64} src={neuronThumbURL}/></div>
        </div> : "");

        return(
            <div style={{position: 'relative'}}>
                {<NeuronVisCanvas width={width} height={height}
                normalize={normalize} index={index}
                setHoveredNeuron={setHoveredNeuron} {...props}/>}
                {hoveredNeuronToolTip}
                {/*<svg width={width} height={height}>
                    <g>
                        {dataArray.map((act, i) => {
                            const normalized = act*normalize;
                            let y, h;
                            if(act < 0) {
                                y = -height/2*(-1-normalized);
                                h = -height/2*normalized;
                            } else {
                                y = height/2;
                                h = height/2*normalized;
                            }
                            return <rect x={i*barWidth} y={y} width={barWidth} height={h} color='#3f4'></rect>
                        })}
                    </g>
                    </svg>*/}
            </div>
        );
    } else {
        return(<div></div>)
    }
}