import {getUint8ToInceptionInputInfo} from './OutputUtils'

/**
 * Is owned by model, stores output requirements from views and provides outputspec inferred from those.
 */
export class OutputManager {
    constructor() {
        this.outputDefGenerators = {}
    }

    /**
     * Visualization components can call this to register their model output requirement.
     * @param {*} outputDefGenerators array of functions that return outputDefs
     */
    registerOutputDefGenerators(viewId, outputDefGenerators) {
        this.outputDefGenerators[viewId] = outputDefGenerators;
    }

    deregisterOutputDefGenerators(viewId) {
        if( viewId in this.outputDefGenerators) {
            delete this.outputDefGenerators[viewId];
        } else {
            throw "view with id "+viewId+" doesn't have any outputs registered here.";
        }
    }

    deregisterAll() {
        this.outputDefGenerators = {}
    }

    /**
     * Returns outputSpec which can be passed to Inferencer constructor.
     */
    getOutputSpec(sceneWidth, sourceModel) {
        let outputSpec = {};
        const inputInfo = getUint8ToInceptionInputInfo(sceneWidth, sourceModel);
        outputSpec.inputInfo = inputInfo;
        const outputGroups = this.aggregateSimilarOutputsToGroups();
        outputSpec.outputGroups = outputGroups;
        return outputSpec;
    }

    /**
     * TODO: For now just puts each into their own group!
     * Possible aggregation heuristic: by layer
     */
    aggregateSimilarOutputsToGroups() {
        const test = this.aggregateSimilarOutputsToGroupsByLayer();

        const outputGroups = [];
        for (var key in  this.outputDefGenerators) {
            const defGenerators = this.outputDefGenerators[key];
            defGenerators.forEach(defGenerator => {
                const outputDef = defGenerator();
                let outputDefs = [];
                outputDefs.push(outputDef);
                let outputGroup = {outputDefs: outputDefs};
                outputGroups.push(outputGroup);
            });
        };
        return outputGroups;
    }

    aggregateSimilarOutputsToGroupsByLayer() {
        const perLayerDefs = {};
        for (var key in  this.outputDefGenerators) {
            const defGenerators = this.outputDefGenerators[key];
            defGenerators.forEach(defGenerator => {
                const outputDef = defGenerator();
                const l = outputDef.layer;
                if(l in perLayerDefs) {
                    perLayerDefs[l].push(outputDef);
                } else {
                    perLayerDefs[l] = [outputDef];
                }
            });
        };
        const outputGroups = [];
        for (var key in perLayerDefs) {
            let outputGroup = {outputDefs: perLayerDefs[key]};
            outputGroups.push(outputGroup);
        }
        return outputGroups;
    }
}