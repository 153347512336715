export function getNeuronThumbLayout(inWidth, inHeight, neuronNum) {
    const coordArray = [];
    const canvasRatio = inWidth / inHeight;
    const neuronsPerSquare = neuronNum / canvasRatio;
    const neuronsPerSquareWidth = Math.sqrt(neuronsPerSquare);
    const cols = Math.ceil(neuronsPerSquareWidth*canvasRatio);
    const w = inWidth/cols;
    const lines = Math.ceil(inHeight / w);
    const h = inHeight / lines;
    console.log(cols, lines, cols*lines)
    for(let i=0; i<neuronNum; i++) {
        const x = (i%cols)*w+w/2;
        const y = -h*Math.floor(i/cols)-w/2;
        coordArray.push({x:x, y:y, w:w, h:h});
    }
    return coordArray;
}