import { Vector3} from 'three'
import {getRotMats} from '../SceneViewUtil'

export const tuningCurveParams = {
  NO_CHANGE: "No Change",
  CAMERA_PITCH: "Camera Pitch",
  CAMERA_YAW: "Camera Yaw",
  CAMERA_ROLL: "Camera Roll",
  SHADING_LIGHT_INFLUENCE: "Light Influence",
  SHADING_TEXTURE_INFLUENCE: "Texture Influence",
  POST_ALPHA: "Alpha",
  BG_BLUR: "Background Blur",
  ADV_ALPHA: "Adversarial Alpha"
}

export const sampleAnimationJson = {
  xAxis: {
      param: tuningCurveParams.CAMERA_YAW,
      rangeMin: -1.0,
      rangeMax: 1.0
  },
  yAxis: {
      param: tuningCurveParams.SHADING_TEXTURE_INFLUENCE,
      rangeMin: 0,
      rangeMax: 1.0
  },
  resolution: 10
}

export function setRenderParam(renderParams, key, value) {
    switch(key) {
      case tuningCurveParams.CAMERA_PITCH:
        renderParams.pitch = value;
        break;
      case tuningCurveParams.CAMERA_ROLL:
        renderParams.roll = value;
        break;
      case tuningCurveParams.CAMERA_YAW:
        renderParams.yaw = value;
        break;
      case tuningCurveParams.SHADING_LIGHT_INFLUENCE:
        renderParams.light = value;
        break;
      case tuningCurveParams.SHADING_TEXTURE_INFLUENCE:
        renderParams.texture = value;
        break;
      case tuningCurveParams.POST_ALPHA:
        renderParams.alpha = value;
        break;
      case tuningCurveParams.BG_BLUR:
        renderParams.bgBlur = value;
        break;
      case tuningCurveParams.ADV_ALPHA:
        renderParams.advAlpha = value;
        break;
      default:
        break;
    }
  }
  
export function setUniformsFromRenderParams(uniforms, bgUniforms, renderParams) {
    const TO_RAD = 1/180*Math.PI;
    const p = 'pitch' in renderParams ? TO_RAD*renderParams.pitch : 0;
    const y = 'yaw' in renderParams ? TO_RAD*renderParams.yaw : 0;
    const r = 'roll' in renderParams ? TO_RAD*renderParams.roll : 0;

    const [preRot, postRot] = getRotMats(new Vector3(p, y, r));

    uniforms.preRot = preRot;
    uniforms.postRot = postRot;

    if('texture' in renderParams) {
      uniforms.textureInfluence = renderParams.texture;
    }
    if('light' in renderParams) {
      uniforms.lightingInfluence = renderParams.light;
    }
    if('alpha' in renderParams) {
      uniforms.alpha = renderParams.alpha;
    }
    if('bgBlur' in renderParams) {
      console.log("bgb", renderParams.bgBlur)
      bgUniforms.lod = renderParams.bgBlur;
    }
  }