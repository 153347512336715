import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { DoubleSide, DataTexture, LuminanceFormat, FloatType } from 'three'
import {NeuronVisPlane} from './NeuronVisPlane'

function Scene(props) {
    const { width, height, activationParams, index} = props;
    let layer = activationParams[index].layer;
    const { gl, scene, camera, size } = useThree()

    camera.left = 0;
    camera.right = width;
    camera.top = 0;
    camera.bottom = -height;
    camera.near = 0.1;
    camera.far = 1000;
    camera.updateProjectionMatrix();

    return (
        <React.Suspense fallback={<mesh />}>

        <group>
            <NeuronVisPlane {...props} layer={layer}/>
        </group>
        </React.Suspense>
        );
}

export function NeuronVisCanvas(props) {
    const { width, height, activationParams, layerActivations } = props;

    return (
    <Canvas style={{width: width, height: height, background: "white" }} orthographic={true} gl2={true}>
        <Scene activationParams={activationParams} layerActivations={layerActivations} {...props} />
    </Canvas>
    )
}
