import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { DoubleSide, DataTexture, LuminanceFormat, FloatType } from 'three'

function Scene(props) {
    const { width, height } = props;

    const { gl, scene, camera, size } = useThree()

    camera.left = -0.5;
    camera.right = 0.5;
    camera.top = 0.5;
    camera.bottom = -0.5;
    camera.near = 0.1;
    camera.far = 1000;
    camera.updateProjectionMatrix();

    let x = -0.5;
    let w = 0;

    return (
        <group>
            {

            }
        </group>);
}



export function TuningCurveRenderer(props) {
    const { width, height, tuningCurveResults, index } = props;
    const canvasRef = useRef();
    let _height = 0;
    if (tuningCurveResults &&
        tuningCurveResults[index]) {
        _height = height;
        if(canvasRef.current) {
            const ctx = canvasRef.current.getContext("2d");
            const w = tuningCurveResults[index].tuningCurvePlot.canvasWidth;
            const h = tuningCurveResults[index].tuningCurvePlot.canvasHeight;
            canvasRef.current.width = w;
            canvasRef.current.height = h;
            ctx.width = w;
            ctx.height = h;
            const imData = tuningCurveResults[index].getImageData();
            ctx.putImageData(imData, 0, 0);
        }
    }

    return (
        <div className="row" style={{ width: width, height: _height,
        transition: "height 1s", overflow: 'hidden'}}>
        <canvas
        ref={canvasRef}
                style={{
                width: width, height: _height,
                background: "lightgray"
            }}/>
            {/*<Canvas style={{
                width: width, height: height,
                background: "lightgray"
            }} orthographic={true}>
                <Scene width={width} height={height} />
        </Canvas>*/}
        </div>
    )
}
