import React, { useState, useMemo } from 'react';
import './App.css';
import './styles.css'
import { getModel } from './Model/Model'
import { Sidebar } from './Components/Sidebar'
import { Side } from 'three';
import { Inferencer } from './Model/Outputs'
import * as tf from '@tensorflow/tfjs'
import { Animation2D } from './Components/SceneComponents/Animation2D'
import { Animation } from './Components/SceneComponents/Animation'
import { handleAnimation, handleTuningCurveData, handleModel } from './AppUtil'
import { model } from '@tensorflow/tfjs';
import { SceneView } from './Components/SceneView'
import { ActivationView } from './Components/ActivationView'
import {archId, getModelPathFromDirName, modelDirNames} from './Model/ModelMetaInfo'
import {ModelManager} from './Model/ModelManager'
import {TextField} from '@material-ui/core'
import * as md5 from 'md5'

const sceneCanvasWidth = 300;

function App() {
    const [customObj, setCustomObj] = useState(null);
    const [animation, setAnimation] = useState(null);
    const [tuningCurveResults, setTuningCurveResults] = useState({});
    const [frameData, setFrameData] = useState(null);
    const [showTuningCurves, setShowTuningCurves] = useState(true);
    const [showSortedNeurons, setShowSortedNeurons] = useState(true);
    const [modelDirs, setModelDirs] = useState({model1: modelDirNames[1], model2: modelDirNames[2]})
    const [compareModels, setCompareModels] = useState(false);
    const [receptiveField, setReceptiveField] = useState(undefined);
    const [showApp, setShowApp] = useState(false);


    const modelManager = useMemo(() => {
        return new ModelManager(2);
    }, []);
    modelManager.setModelDirs(modelDirs);

    const model = useMemo(() => {
        return modelManager.getModel(0, true);
    },[modelDirs.model1]);
    let model2 = useMemo(() => {
        return modelManager.getModel(1, compareModels);
    }, [compareModels, modelDirs.model2]);
    if(!model2.isLoaded()) {
        model2 = null;
    }


    const onNewFrame = (data, width, height) => {
        handleModel(model, sceneCanvasWidth, animation, data, width, height);
        if(model2) {
            handleModel(model2, sceneCanvasWidth, animation, data, width, height);
        }
        handleAnimation(animation, setAnimation);
        setFrameData({ data: data, width: width, height: height })
    }

    const animationCallback = (tcParams) => (tuningCurveData) => {
        const ret = handleTuningCurveData(
            tuningCurveResults,
            tuningCurveData,
            tcParams.saveTCThumbs,
            tcParams.saveData,
            tcParams.saveRawImages);
        if (ret) {
            setTuningCurveResults(ret);
        }
    }
    const onStartTCAnimation = (params) => {
        if (params) {
            //TODO: find better solution for this. this is necessary
            // currently to reset the tuning curve results before animation
            Object.entries(tuningCurveResults).forEach(([key, value]) => {
                delete tuningCurveResults[key];
            });
            let animation = null;
            let paramCount = 0;
            paramCount += ("xAxis" in params) ? 1 : 0;
            paramCount += ("yAxis" in params) ? 1 : 0;
            if(paramCount == 2) {
                animation = new Animation2D(
                    params.xAxis, params.yAxis,
                    animationCallback(params), params.resolution);
            } else if (paramCount == 1) {
                animation = new Animation(("xAxis" in params) ? params.xAxis : params.yAxis,
                animationCallback(params), params.resolution);
            }

            setAnimation(animation);
        }
    }
    return (
        <div className="App">
            {!showApp && <TextField onChange={(event) => {
                if (md5(event.target.value) === "00fa5616ac89406bc6f682fc43eb7b22") {
                    setShowApp(true);
                }
            }}></TextField>}
            {showApp && 
            <div className="row">
                <Sidebar width={250} onCustomObjLoaded={(obj) => {
                    const customObj = { obj: obj, position: [0.08, -0.08, 0] };
                    setCustomObj(customObj);
                }}
                    onStartTCAnimation={onStartTCAnimation}
                    showSortedNeurons={showSortedNeurons}
                    setShowSortedNeurons={setShowSortedNeurons}
                    showTuningCurves={showTuningCurves}
                    setShowTuningCurves={setShowTuningCurves}
                    compareModels={compareModels}
                    setCompareModels={setCompareModels}
                    modelDirs={modelDirs}
                    setModelDirs={setModelDirs}
                />
                <div id="layout">
                    <div className="row">
                        <div className="column">
                            <SceneView receptiveField={receptiveField}
                             model={model}
                                customObj={customObj}
                                animation={animation}
                                onNewFrame={onNewFrame}
                                sceneWidth={sceneCanvasWidth}
                                frameData={frameData}
                            />
                        </div>
                        {<div className="column">
                            <ActivationView
                                animation={animation}
                                modelManager={modelManager}
                                receptiveField={receptiveField}
                                sceneWidth={sceneCanvasWidth}
                                updateReceptiveField={() => {
                                    setReceptiveField(model.getReceptiveField());
                                }}
                                frameData={frameData}
                                tuningCurveResults={tuningCurveResults}
                                showTuningCurves={showTuningCurves}
                                showSortedNeurons={showSortedNeurons}
                            />
                        </div>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default App;
