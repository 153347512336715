import React, {useState} from 'react'
import {modelDirNames} from '../../Model/ModelMetaInfo'
import {Button, Dialog, DialogActions, DialogContent,
  DialogTitle,FormControlLabel, Checkbox,
  FormControl, MenuItem, Select, InputLabel} from '@material-ui/core';

export function ModelSelectionDialog(props) {
    const{open, handleClose, modelDirs} = props;

    const [model1Dir, setModel1Dir] = useState(modelDirs.model1);
    const [model2Dir, setModel2Dir] = useState(modelDirs.model2);

    return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">Select models:</DialogTitle>
          <DialogContent>
            <FormControl style={{minWidth: '100%'}}>
              <InputLabel id="demo-simple-select-label">Model 1</InputLabel>
              <Select
                  width='100%'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model1Dir}
                onChange={evt => setModel1Dir(evt.target.value)}
              >
                  {
                      modelDirNames.map((weightDir) => {
                          return <MenuItem value={weightDir}>{weightDir}</MenuItem>
                      })
                  }
              </Select>
            </FormControl>
            <FormControl style={{minWidth: '100%'}}>
              <InputLabel id="demo-simple-select-label">Model 2</InputLabel>
              <Select
                  width='100%'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model2Dir}
                onChange={evt => setModel2Dir(evt.target.value)}
              >
                  {
                      modelDirNames.map((weightDir) => {
                          return <MenuItem value={weightDir}>{weightDir}</MenuItem>
                      })
                  }
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" onClick={() => {
                handleClose({});
                }}>
              Cancel
            </Button>
            <Button onClick={() => {
                handleClose({model1: model1Dir, model2: model2Dir});
            }} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
    );
}