import React, { useRef } from 'react';
import { extend, useThree, useFrame } from 'react-three-fiber';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

export function Controls() {
  const controlsRef = useRef();
  const { camera, gl } = useThree();

  const gl_context = gl.domElement.getContext("webgl");

  useFrame(() => {
      controlsRef.current && controlsRef.current.update();
  });

  return (
    <orbitControls
      screenSpacePanning={true}
      ref={controlsRef}
      args={[camera, gl.domElement]}
      enableRotate
      enablePan={true}
      maxDistance={100}
      minDistance={.3}
    />
  );
}