import {Matrix4, Euler} from 'three'

export function getReceptiveFieldRect(sceneWidth, receptiveField) {
    const {rfWidth, fmWidth, inputWidth} = receptiveField ?
    receptiveField : {rfWidth:sceneWidth, fmWidth:sceneWidth, inputWidth: sceneWidth};
    const centerOffset = Math.ceil((fmWidth-1)/2) - (fmWidth-1)/2;
    const offsetRatio = centerOffset / fmWidth;

    const rfMargin = (inputWidth - rfWidth) / 2;
    const rfMarginRatio = rfMargin / inputWidth + offsetRatio;
    const rfCanvasMargin = sceneWidth * rfMarginRatio;
    const rfWidthRatio = rfWidth / inputWidth;
    const rfCanvasWidth = sceneWidth * rfWidthRatio;
    return{t:rfCanvasMargin, l: rfCanvasMargin,
        w:rfCanvasWidth, h: rfCanvasWidth    
    }
}

export function getRotMats(rotVector) {
    const {x, y, z} = rotVector;
    const preRot = new Matrix4();
    preRot.makeRotationFromEuler(new Euler(x, y, 0, 'XZY'));

    const postRot = new Matrix4();
    postRot.makeRotationFromEuler(new Euler(0, 0, z, 'XZY'));
    return [preRot, postRot];
}