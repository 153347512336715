//import * as tfjs_node from '@tensorflow/tfjs-node';
import * as tfjs_node from '@tensorflow/tfjs';
import * as tfjs from '@tensorflow/tfjs';
export let tf;
if (typeof window === 'undefined') {
    tf = tfjs_node;
    console.log("using tfjsnode")
} else {
    tf = tfjs;
    console.log("using tfjs")
}