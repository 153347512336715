import React, { useState, useMemo } from 'react';
import {getLayerActivationVisColumn,
    getNeuronIndicatorDivs, getDefGenerators, getCanvasWidth, getPlusMinusView,
    getDefaultActivations, defaultActivationParams, getEnabledDefGenerators,
    getActivationViewKey} from './ActivationViewUtil'
import { ActivationCanvas } from './ActivationComponents/ActivationRenderer'
import {getTestLayerActivations} from './LayerComponents/LayerViewUtil'
import * as config from '../config'
import {getDefaultActivationParams, getDefaultNewActivationParams} from '../Model/ModelMetaInfo'



export function ActivationView(props) {
    const { modelManager, sceneWidth, animation,
        receptiveField, updateReceptiveField} = props;
    const neuronWidth = sceneWidth/2.0;

    const [modelInds, setModelInds] = useState([0]);

    const model = modelManager.getModel(modelInds[0], true);

    const [activations, setActivations] = useState(getDefaultActivations());
    const [activationParams, setActivationParams] = useState(getDefaultActivationParams(model.archType));
    const [layerActivations, setLayerActivations] = useState({0: getTestLayerActivations()});

    const sanitizeActivationParams = () => {
        let set = false;
        let newActivationParams = [];
        modelInds.forEach((modelInd, paramInd) => {
            const m = modelManager.getModel(modelInd);
            if (activationParams[paramInd].archId !== m.archType) {
                const arch = m.archType;
                newActivationParams.push(getDefaultNewActivationParams(arch));
                set = true;
            } else {
                newActivationParams.push(activationParams[paramInd]);
            }
        });
        if (set) {
            setActivationParams(newActivationParams);
        }
        return newActivationParams;
    }
    const sanitizedActivationParams = sanitizeActivationParams();

    const wrappedSetModelInds = (newModelInds) => {
        const newActivationParams = []
        modelInds.forEach((modelInd, paramInd) => {
            const newModelInd = newModelInds[paramInd];
            if (newModelInd !== modelInd) {
                modelManager.getModel(modelInd).outputManager.deregisterOutputDefGenerators(getActivationViewKey(paramInd));
            }
            const oldArch = modelManager.getModel(modelInd).archType;
            const newArch = modelManager.getModel(newModelInd).archType;
            if(oldArch !== newArch) {
                newActivationParams.push(getDefaultNewActivationParams(newArch));
            } else {
                newActivationParams.push(sanitizedActivationParams[paramInd]);
            }
        });
        setActivationParams(newActivationParams);
        setModelInds(newModelInds);
    }

    model.setReceptiveFieldLayer(sanitizedActivationParams[0].layer);
    if(!receptiveField) {
        updateReceptiveField();
    }
    const rfUpdate=() => {
        model.setReceptiveFieldLayer(sanitizedActivationParams[0].layer);
        updateReceptiveField();
    }

    // TODO: the problem is, that defgenerators are registered for "model" with all activationParameters.
    // the activationParameters should each be registered to their corresponding model.
    // also, they need to know which activations to set. we might just need to pass the activ param index for the def generators,
    // which then also gets used by setActivations.
    modelInds.forEach((modelInd, i) => {
        const defGenerators = getDefGenerators(getEnabledDefGenerators(props),
        activations, i, setActivations, sanitizedActivationParams, animation,
        layerActivations, setLayerActivations,);
        const m = modelManager.getModel(modelInd);
        m.outputManager.registerOutputDefGenerators(getActivationViewKey(i), defGenerators);
    });

    const canvasWidth = getCanvasWidth(sanitizedActivationParams, neuronWidth);
    const columnProps = {
        props: props, activationParams: sanitizedActivationParams,
        setActivationParams: setActivationParams,
        layerActivations: layerActivations,
        neuronWidth:neuronWidth, updateReceptiveField:rfUpdate,
        modelInds:modelInds, setModelInds:wrappedSetModelInds
    };
    return (
        <div className={'column'}>
            <div style={{width:canvasWidth, height:neuronWidth,
                position: 'relative'}}>
                <ActivationCanvas width={canvasWidth} height={neuronWidth}
                    activationParams={sanitizedActivationParams} activations={activations} />
                {config.SHOW_RECEPTIVE_FIELD ? getNeuronIndicatorDivs(neuronWidth, activations, sanitizedActivationParams) : ""}
            </div>
            <div className='row'>
            {getLayerActivationVisColumn(columnProps)}
            {getPlusMinusView(modelManager, sanitizedActivationParams, setActivationParams,
                activations, setActivations, modelInds, setModelInds)}
            </div>
        </div>
    );
}