import {Matrix4} from 'three'


export default {
    uniforms: {
        color: { type: 't', value: null},
        alpha: {value: 1.0},
        lod: {value: 0.0},
        zPos: {value: 1.0}
    },
  
    vertexShader: [
        'uniform float zPos;',
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = vec4(position, 1.0);',
        '    gl_Position.z = zPos;',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D color;',
        'uniform float alpha;',
        'uniform float lod;',
        'varying vec2 vUV;',
        
        'void main(void) {',
        '    vec3 col = textureLod(color, vUV, lod).rgb;',
        '    //col.xy = vUV;',
            
        '    gl_FragColor = vec4(col*alpha, alpha);',
        '}'
    ].join('\n')
  }