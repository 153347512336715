import React, {useState, useRef, useMemo} from 'react';
import {Matrix4, Vector3, Euler} from 'three';
import { SceneCanvas } from './SceneComponents/SceneRenderer'
import { CustomSlider } from './ParamControls/CustomSlider'
import {Button, TextField, Checkbox, FormControlLabel} from '@material-ui/core'
import * as config from '../config'
import {getReceptiveFieldRect, getRotMats} from './SceneViewUtil'
import { frame } from '@tensorflow/tfjs';
import {PGDAttack} from '../Model/Attacks'
import {IterativeProcessingOverlay} from './SceneComponents/IterativeProcessingOverlay'
import {getRenderParams, tuningCurveParams} from './SceneComponents/AnimationUtils'


export function SceneView(props) {
    const {sceneWidth, model, onNewFrame, receptiveField, animation} = props;
    const width = sceneWidth;

    const [iterativeProcess, setIterativeProcess] = useState(null);
    const [iterativeProcessAlpha, setIterativeProcessAlpha] = useState(1.0);
    const [overlayImage, setOverlayImage] = useState(null);
    const [lpNorm, setLpNorm] = useState("2")
    const [uniforms, setUniforms] = useState({});
    const [bgUniforms, setBgUniforms] = useState({});
    const [rot, setRot] = useState(new Vector3());
    const [effectParams, setEffectParams] = useState({alpha: 1.0, bloom: 0.0});

    const uniformChanged = (unif, changedUniforms) => {
        Object.keys(changedUniforms).forEach((key) => {
            unif[key] = changedUniforms[key];
        });
    }

    const {t, l, w, h} = getReceptiveFieldRect(width, receptiveField);

    const [preRot, postRot] = getRotMats(rot);
    uniformChanged(uniforms, {postRot: postRot, preRot: preRot});

    const epsRef = useRef();

    const initIterativeProcess = (data, width, height) => {
        const eps = parseFloat(epsRef.current.value);
        const attack = new PGDAttack(
            model.sourceModel,
            {data:data, width:width, height:height}, eps, lpNorm);
        const overlayImage = 
        attack.doStep(eps);
        setOverlayImage(overlayImage);
        setIterativeProcess(attack);
    }

    let advAlpha = iterativeProcessAlpha;
    if(animation) {
        const renderParams = animation.getRenderParams();
        if ("advAlpha" in renderParams) {
            advAlpha = renderParams.advAlpha;
        }
    }
    let wrappedOverlayImage = useMemo(() => {
        if(advAlpha != iterativeProcessAlpha && iterativeProcess) {
            return iterativeProcess.getCurrent(advAlpha);
        } else {
            return overlayImage;
        }
    }, [advAlpha, overlayImage]);

    return (
        <div style={{width: width}}>
            <div style={{width:width, height:width,
                display: 'grid', gridTemplate:'1fr / 1fr',
                position: 'relative'}}>
                <SceneCanvas
                {...props}
                bgUniforms={bgUniforms}
                uniforms={uniforms}
                overlayImage={wrappedOverlayImage}
                effectParams={effectParams}
                initIterativeProcess={iterativeProcess === 'init' ? initIterativeProcess : undefined}
                />
                <IterativeProcessingOverlay width={width} height={width}
                overlayImage={wrappedOverlayImage} handleMouseDown={() => {
                    setIterativeProcess(null);
                    setOverlayImage(null);
                }} />
                <div style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    top: t,
                    left: l,
                    width:w,
                    height:h,
                    zIndex: 1,
                    border:'2px dotted white',
                    visibility:config.SHOW_RECEPTIVE_FIELD?'visible':'hidden'}}>
                </div>
            </div>
            <div style={{padding: "10px", width: width}}>
            <CustomSlider 
                labelText="Background blur"
                defaultValue={0}
                valueLabelDisplay={"auto"}
                step={0.05}
                min={0}
                max={10.0}
                onChange={(evt, value) => uniformChanged(bgUniforms, {lod: value})}
            />
            <CustomSlider 
                labelText="Texture influence"
                defaultValue={1}
                valueLabelDisplay={"auto"}
                step={0.02}
                min={0}
                max={1}
                onChange={(evt, value) => uniformChanged(uniforms, {textureInfluence: value})}
            />
            <CustomSlider 
                labelText="Lighting influence"
                defaultValue={1}
                valueLabelDisplay={"auto"}
                step={0.02}
                min={0}
                max={1}
                onChange={(evt, value) => uniformChanged(uniforms, {lightingInfluence: value})}
            />
            <CustomSlider 
                labelText="Alpha"
                defaultValue={1}
                valueLabelDisplay={"auto"}
                step={0.02}
                min={0}
                max={1}
                onChange={(evt, value) => {
                    let newEff = {...effectParams};
                    newEff.alpha = value;
                    setEffectParams(newEff);
                }}
            />
            <CustomSlider 
                labelText="Roll"
                defaultValue={0}
                valueLabelDisplay={"auto"}
                step={2}
                min={-180}
                max={180}
                onChange={(evt, value) =>{
                    const newRot = new Vector3(rot.x, rot.y, rot.z);
                    newRot.z = value/180*Math.PI;
                    setRot(newRot);
                }}
            />
            <CustomSlider 
                labelText="Pitch"
                defaultValue={0}
                valueLabelDisplay={"auto"}
                step={2}
                min={-180}
                max={180}
                onChange={(evt, value) =>{
                    const newRot = new Vector3(rot.x, rot.y, rot.z);
                    newRot.x = value/180*Math.PI;
                    setRot(newRot);
                }}
            />
            <CustomSlider 
                labelText="Yaw"
                defaultValue={0}
                valueLabelDisplay={"auto"}
                step={2}
                min={-180}
                max={180}
                onChange={(evt, value) =>{
                    const newRot = new Vector3(rot.x, rot.y, rot.z);
                    newRot.y = value/180*Math.PI;
                    setRot(newRot);
                }}
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={lpNorm==="2"}
                    onChange={(evt, val) => {
                        setLpNorm(lpNorm==="2"?"inf":"2");
                    }}
                    value={lpNorm==="2"}
                />
                }
                label={"Using L-"+lpNorm+" norm"}
            />
            <CustomSlider 
                labelText="Attack Alpha"
                defaultValue={1}
                valueLabelDisplay={"auto"}
                step={0.01}
                min={0}
                max={1.0}
                onChange={(evt, value) =>{
                    setIterativeProcessAlpha(value);
                    if(!iterativeProcess) {
                        return;
                    }
                    const overlayImage = 
                    iterativeProcess.getCurrent(value);
                    setOverlayImage(overlayImage);
                }}
            />
            <div className="row">
            <Button style={{width:"50%", margin:5}} variant="contained" color="primary" onClick={() => {
            if(!iterativeProcess) {
                setIterativeProcess('init');
            } else {
                const eps = epsRef.current.value;
                const overlayImage = 
                iterativeProcess.doStep(parseFloat(eps));
                setOverlayImage(overlayImage);
            }
            }}
            >PGD Step</Button>
            <TextField inputRef={epsRef} style={{width:"50%", margin:5}}
            defaultValue={10}/>
            </div>
            </div>
        </div>
    );
}