import React, {useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {loadCustomModel} from './ObjectUtil'

export function FormDialog(props) {
const {open, handleClose} = props;
const [objFile, setObjFile] = useState(null);
const [diffTexFile, setDiffTexFile] = useState(null);
const [alphaTexFile, setAlphaTexFile] = useState(null);

const objFilesRef = useRef();
const diffFilesRef = useRef();
const alphaFilesRef = useRef();

  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Load custom 3d model</DialogTitle>
        <DialogContent>
            <DialogContentText>{objFile ? objFile.name : "Choose obj file"}</DialogContentText>
            <Button
                variant="contained"
                component="label"
                >
                Choose obj
                <input
                    type="file"
                    ref={objFilesRef}
                    style={{ display: "none" }}
                    onChange={(ev) => {
                        ev.preventDefault();
                        setObjFile(objFilesRef.current.files[0]);
                    }}
                />
            </Button>
            <DialogContentText>{diffTexFile ? diffTexFile.name : "Choose diffuse texture"}</DialogContentText>
            <Button
                variant="contained"
                component="label"
                >
                Choose image
                <input
                    type="file"
                    ref={diffFilesRef}
                    style={{ display: "none" }}
                    onChange={(ev) => {
                        ev.preventDefault();
                        setDiffTexFile(diffFilesRef.current.files[0]);
                    }}
                />
            </Button>
            <DialogContentText>{alphaTexFile ? alphaTexFile.name : "Choose alpha texture"}</DialogContentText>
            <Button
                variant="contained"
                component="label"
                >
                Choose image
                <input
                    type="file"
                    ref={alphaFilesRef}
                    style={{ display: "none" }}
                    onChange={(ev) => {
                        ev.preventDefault();
                        setAlphaTexFile(alphaFilesRef.current.files[0]);
                    }}
                />
            </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" onClick={() => {
              handleClose();
                setObjFile(null);
                setDiffTexFile(null);
                setAlphaTexFile(null);
              }}>
            Cancel
          </Button>
          <Button onClick={() => {
              handleClose({objFile: objFile, diffTexFile: diffTexFile, alphaTexFile: alphaTexFile });
          }} color="primary">
            Load
          </Button>
        </DialogActions>
      </Dialog>
  );
}
