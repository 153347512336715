import {tf} from '../TFJS'

export function preProcessRawUint8InputImage (
    data, fromSize, toSize={w:224, h:224},
    inValRange={min:0, max:255}, outValRange={min:-1, max:1}) {
    const getDimArray = (whObject) => {
        const {w, h} = whObject;
        return [w, h];
    }
    const [fW, fH] = getDimArray(fromSize);
    const [tW, tH] = getDimArray(toSize);

    const getRangeArray = (mmObject) => {
        const {min,max} = mmObject;
        return [min, max];
    }
    const [iMin, iMax] = getRangeArray(inValRange);
    const [oMin, oMax] = getRangeArray(outValRange);
    const iScale = iMax - iMin;
    const oScale = oMax - oMin;

    let img = tf.tensor(data,
        [1, fH, fW, 4], 'float32');
    img = tf.image.resizeBilinear(img, [tW, tH]).sub(iMin).div(iScale).mul(oScale).add(oMin);
    img = tf.reverse(img, 1);
    img = img.slice([0, 0, 0, 0], [1, tH, tW, 3]);
    return img;
}

export function postProcessImageTensor(imageTensor, fromSize, inValRange={min:-1, max:1}, outValRange={min:0, max:255}) {
    const {width, height} = fromSize;
    const [b, h, w, c] = imageTensor.shape;
    const alpha = tf.fill([b, h, w, 1], 1.0);
    const rgba = tf.concat([imageTensor, alpha], 3);

    const getRangeArray = (mmObject) => {
        const {min,max} = mmObject;
        return [min, max];
    }
    const resized = tf.image.resizeBilinear(rgba, [width, height]);
    const [iMin, iMax] = getRangeArray(inValRange);
    const [oMin, oMax] = getRangeArray(outValRange);
    const iScale = iMax - iMin;
    const oScale = oMax - oMin;
    const rangeCorrected = resized.sub(iMin).div(iScale).mul(oScale).add(oMin);
    return rangeCorrected.asType('int32');
}

export function getCenterNeuronCoords(inputShape={w:32, h:32}) {
    const {w, h} = inputShape;
    const cx = Math.ceil((w-1) / 2.0);
    const cy = Math.ceil((h-1) / 2.0);
    return {x:cx, y:cy};
}

export function getCenterRectCoordsFromWidth(width, inputShape={w:32, h:32}) {
    const {w, h} = inputShape;
    if(width === -1) {
        return {x:0, y: 0, w:w, h:h};
    }
    const cx = (w-1) / 2.0;
    const cy = (h-1) / 2.0;
    const leftX = Math.ceil(cx+0.5-width/2.0);
    const topY = Math.ceil(cy+0.5-width/2.0);
    return {x:leftX, y:topY, w:width, h:width};
}