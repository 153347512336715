import React, {useMemo} from 'react';
import { rgbToHex } from '@material-ui/core';
import { CollectionsBookmarkSharp } from '@material-ui/icons';

const getRandomColors = (num) => {
    let colors = [];
    for(let i=0; i<num; i++) {
        const r = Math.random()*255;
        const g = Math.random()*255;
        const b = Math.random()*255;
        const clr = "rgb("+r+","+g+","+b+")";
        colors.push(clr);
    }
    return colors;
}

const getCurvePathString = (w, h, vals) => {
    const xMax = vals.length - 1;
    let str = "";
    for(let i=0; i<=xMax; i++) {
        const x = i/xMax * w;
        const y = h - vals[i] * h;
        str += (i > 0 ? "L " : "M ")+x+" "+y+" ";
    }
    str += "L "+w+" 0 ";
    return str;
};

export function TuningCurveLinePlot(props) {
    const { width, height, tuningCurveResults, index } = props;

    const tcResult = tuningCurveResults[index];
    const dataLen = tcResult ? Object.entries(tcResult.inferenceData).length : 0;

    const lineVals = useMemo(() => {
        if (dataLen === 0) {
            const vals = [];
            for(let i=0; i<dataLen; i++) {
                const v = Math.sin(i) / 2.0 + 0.5;
                vals.push(v);
            }
            return [vals];
        } else {
            const neuronNum = tcResult.inferenceData[0].data.length;
            const valsPerNeuron = [];
            for(let n=0; n<neuronNum; n++) {
                const valsPerFrame = [];
                for(let f=0; f<dataLen; f++) {
                    const actualNeuronNum = tcResult.inferenceData[f].data.length;
                    if (n<actualNeuronNum) {
                        let v = tcResult.inferenceData[f].data[n] / Math.sqrt(tcResult.inferenceData[f].variance);
                        v = v / 2.0 + 0.5;
                        valsPerFrame.push(v);
                    }
                }
                valsPerNeuron.push(valsPerFrame);
            }
            return valsPerNeuron;
        }
    }, [dataLen]);

    const colors = useMemo(() => {
        return getRandomColors(1000);
    }, []);

    const curvePathStrings = useMemo(() => {return lineVals.map((valsPerNeuron, i) => {
        return getCurvePathString(width, height, valsPerNeuron);
    });}, [lineVals]);;

    return (
        <div style={{width: width, height:height}}>
            <svg width={width} height={height}>
                <g>
                {curvePathStrings.map((curvePathString, i) => {
                    return (
                        <path d={curvePathString}
                        fill="transparent"
                        stroke={colors[i]}
                        strokeWidth="1"/>
                    );
                })}
                </g>
            </svg>
        </div>
    );
}