import React, { useRef, useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { DoubleSide, DataTexture, LuminanceFormat, FloatType } from 'three'
import {ActivationPlane} from './ActivationPlane'

function Scene(props) {
    const { width, height, activationParams, activations } = props;

    const { gl, scene, camera, size } = useThree()

    camera.left = -0.5;
    camera.right = 0.5;
    camera.top = 0.5;
    camera.bottom = -0.5;
    camera.near = 0.1;
    camera.far = 1000;
    camera.updateProjectionMatrix();

    const totalNeurons = () => {
        let ret = 0;
        activationParams.forEach(params => {
            ret += params.neurons.length;
        });
        return ret;
    }

    let x = -0.5;
    let w = 0;
    const planeParams = activationParams.map((params, i) => {
        const n = params.neurons;
        x += w / 2;
        w = n.length / totalNeurons();
        x += w / 2;
        return [x, w];
    });
    return (
        <group>
            {
                activationParams.map((params, i) => {
                    return (<ActivationPlane w={planeParams[i][1]} x={planeParams[i][0]}
                        params={params} activation={activations.act[i]}/>);
                })
            }
        </group>);
}

export function ActivationCanvas(props) {
    const { width, height, activationParams, activations } = props;

    return (
    <Canvas style={{width: width, height: height, background: "lightgray" }} orthographic={true}>
        <Scene width={width} height={height} activationParams={activationParams} activations={activations} />
    </Canvas>
    )
}
