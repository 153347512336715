import {tuningCurveParams} from './AnimationUtils';
import {Animation, checkAxisParams} from './Animation';
import {setRenderParam} from './AnimationUtils';

export class Animation2D extends Animation{
    constructor(xAxis, yAxis, callback, resolution=10) {
        if(!xAxis || !yAxis) {
            throw "Need to specify xAxis and yAxis!"
        }else if(!checkAxisParams(xAxis) || !checkAxisParams(yAxis)) {
            throw "Invalid axis params!";
        }
        super(xAxis, callback, resolution)
        this.yAxis = yAxis;
        this.frame = 0;
    }

    maxFrames() {
        return this.resolution*this.resolution;
    }

    getRenderParams(){
        const frame = this.frame;
        const x = frame % this.resolution;
        const y = Math.floor(frame / this.resolution);
      
        const xRange = this.xAxis.rangeMax - this.xAxis.rangeMin;
        const xVal = xRange / (this.resolution-1.0) * x + this.xAxis.rangeMin;
      
        const yRange = this.yAxis.rangeMax - this.yAxis.rangeMin;
        const yVal = yRange / (this.resolution-1.0) * y + this.yAxis.rangeMin;
      
        let renderParams = {};
        setRenderParam(renderParams, this.xAxis.param, xVal);
        setRenderParam(renderParams, this.yAxis.param, yVal);
        return renderParams;
      }
}