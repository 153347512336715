export function getUint8ToInceptionInputInfo(sceneWidth, sourceModel) {
    const rawInputShape = [1, sceneWidth, sceneWidth, 4];
    const s = sourceModel.input.shape;
    const inputShape = [1, s[1], s[2], s[3]];
    const rawInputRange = [0, 255];
    const rawOutputRange = [-1, 1];
    return {
        rawInputShape: rawInputShape,
        inputShape: inputShape,
        rawInputRange:rawInputRange,
        rawOutputRange: rawOutputRange
    }
}


export function getPoolWidthFromDefParam(defParam, h, w) {
    let pw = defParam;
    pw = pw === -1 ? Math.min(h,w) : pw;
    pw = pw > Math.min(h,w) ? Math.min(h,w) : pw;
    return pw;
}