import React, { useState, useRef } from 'react'
import { imnetNameFromIndex } from '../../ImageNetUtil'
import { Typography } from '@material-ui/core'

export function IterativeProcessingOverlay(props) {
    const { overlayImage, handleMouseDown, width, height } = props;

    let origClsName, currClsName, origProb, currOrigProb, currProb;
    if (overlayImage) {
        const { startPred, currentPred } = overlayImage;
        const { prob, cls, origClsProb } = currentPred;
        const origCls = startPred.cls;
        origClsName = imnetNameFromIndex(origCls);
        currClsName = imnetNameFromIndex(cls);
        origProb = startPred.prob.toFixed(3);
        currOrigProb = origClsProb.toFixed(3);
        currProb = prob.toFixed(3);
    }
    const rStyle = {
        position: 'absolute', width: "100%", height: "25%", padding: 5,
        left: 0, bottom: 0, background: "white", opacity: 0.5,
    };

    const getTypographyRow = (content, fontSize) => {
        let style={};
        if(fontSize){
            style["fontSize"] = fontSize;
        }
        return<div className="row" >
            <Typography variant="body1" style={style}>{content}</Typography>
        </div>
    }

    return <div style={{
        position: 'absolute', width: width, height: height,
        visibility: overlayImage ? 'visible' : 'hidden'
    }}
        onMouseDown={handleMouseDown}>
        <div className="row"
            style={rStyle}>
            <div style={{ width: "50%", height: "100%" }} className="column">
                {getTypographyRow("Orig. pred.")}
                {getTypographyRow(origClsName+": "+origProb, 12)}
            </div>
            <div style={{ width: "50%", height: "100%" }} className="column">
                {getTypographyRow("Curr. pred.")}
                {getTypographyRow(origClsName+": "+currOrigProb, 12)}
                {getTypographyRow(currClsName+": "+currProb, 12)}
          </div>
        </div>
    </div>
}