export const archId = {
    INCEPTION_V1: "Inception V1",
    RESNET_18: "Resnet 18"
}

export const modelDirNames = [
    'InceptionV1',
    'ckpt__std',
    'ckpt_adv_l2_1.0'
]

export function getModelPathFromDirName(dirName) {
    const base = process.env.PUBLIC_URL;
    if (dirName.includes('ckpt')) {
        return base + "Resnet18/" + dirName + "/model.json";
    } else {
        return base + dirName + "/model.json";
    }
}

export function getArchFromDirName(dirName) {
    if (dirName.includes('ckpt')) {
        return archId.RESNET_18;
    } else {
        return archId.INCEPTION_V1;
    }
}

const inceptionV1receptiveFieldDict = {
    'mixed3a': 43,
    'mixed3b': 59,
    'mixed4a': 107,
    'mixed4b': 139,
    'mixed4c': 171,
    'mixed4d': 203,
    'mixed4e': 235, 
    'mixed5a': 315,
    'mixed5b': 379
}

const resnet18receptiveFieldDict =  {
}

const originalResnet18Layer2Layer = {
    'conv2d_1': 'conv0/W',
    'batch_normalization_1': 'conv0/bn/FusedBatchNormV3',

    'res0a_branch2a': 'group0/block0/conv1/W',
    'bn0a_branch2a': 'group0/block0/conv1/bn/FusedBatchNormV3',
    'res0a_branch2b': 'group0/block0/conv2/W',
    'bn0a_branch2b': 'group0/block0/conv2/bn/FusedBatchNormV3',
    'res0b_branch2a': 'group0/block1/conv1/W',
    'bn0b_branch2a': 'group0/block1/conv1/bn/FusedBatchNormV3',
    'res0b_branch2b': 'group0/block1/conv2/W',
    'bn0b_branch2b': 'group0/block1/conv2/bn/FusedBatchNormV3',

    'res1a_branch2a': 'group1/block0/conv1/W',
    'bn1a_branch2a': 'group1/block0/conv1/bn/FusedBatchNormV3',
    'res1a_branch2b': 'group1/block0/conv2/W',
    'bn1a_branch2b': 'group1/block0/conv2/bn/FusedBatchNormV3',
    'res1a_branch1': 'group1/block0/convshortcut/W',
    'bn1a_branch1': 'group1/block0/convshortcut/bn/FusedBatchNormV3',
    'res1b_branch2a': 'group1/block1/conv1/W',
    'bn1b_branch2a': 'group1/block1/conv1/bn/FusedBatchNormV3',
    'res1b_branch2b': 'group1/block1/conv2/W',
    'bn1b_branch2b': 'group1/block1/conv2/bn/FusedBatchNormV3',

    'res2a_branch2a': 'group2/block0/conv1/W',
    'bn2a_branch2a': 'group2/block0/conv1/bn/FusedBatchNormV3',
    'res2a_branch2b': 'group2/block0/conv2/W',
    'bn2a_branch2b': 'group2/block0/conv2/bn/FusedBatchNormV3',
    'res2a_branch1': 'group2/block0/convshortcut/W',
    'bn2a_branch1': 'group2/block0/convshortcut/bn/FusedBatchNormV3',
    'res2b_branch2a': 'group2/block1/conv1/W',
    'bn2b_branch2a': 'group2/block1/conv1/bn/FusedBatchNormV3',
    'res2b_branch2b': 'group2/block1/conv2/W',
    'bn2b_branch2b': 'group2/block1/conv2/bn/FusedBatchNormV3',

    'res3a_branch2a': 'group3/block0/conv1/W',
    'bn3a_branch2a': 'group3/block0/conv1/bn/FusedBatchNormV3',
    'res3a_branch2b': 'group3/block0/conv2/W',
    'bn3a_branch2b': 'group3/block0/conv2/bn/FusedBatchNormV3',
    'res3a_branch1': 'group3/block0/convshortcut/W',
    'bn3a_branch1': 'group3/block0/convshortcut/bn/FusedBatchNormV3',
    'res3b_branch2a': 'group3/block1/conv1/W',
    'bn3b_branch2a': 'group3/block1/conv1/bn/FusedBatchNormV3',
    'res3b_branch2b': 'group3/block1/conv2/W',
    'bn3b_branch2b': 'group3/block1/conv2/bn/FusedBatchNormV3',

    'dense_1': 'linear'
}

function getResnetLayerName(originalResnetLayerName) {
    if(! originalResnetLayerName.includes("group")) {
        return "";
    }
    const tokens = originalResnetLayerName.split("/")
    const group = parseInt(tokens[0][5]);
    const block = parseInt(tokens[1][5]);
    const convStr = tokens[2][4];
    const conv = parseInt(convStr);
    return "layer_import_group"+group+"_block"+block+"_Relu";
}

export function getNeuronThumbURL(model, layerName, neuron) {
    const aId = model.archType;
    const modelPath = model.modelPath.split("/")[1];
    switch(aId){
        case archId.INCEPTION_V1:{
            const cleanLayer = layerName.split("_")[0];
            const src = "https://storage.googleapis.com/fls/nickc/neuron_renders/" + cleanLayer + "_" + neuron + ".jpg";
            return src;
        }
        case archId.RESNET_18:{
            const origLayer = originalResnet18Layer2Layer[layerName];
            console.log("Resnet18", model.archType);
            const src = process.env.PUBLIC_URL + "neuron_thumbs_resnet/"+modelPath+"/"+
            getResnetLayerName(origLayer)+"/neuron_"+(neuron+'').padStart(4, '0')+".jpg";
            return src;
        }
        default:
            return "";
    }
}

export function getLayerImageURL(model, layerName) {
    const aId = model.archType;
    const modelPath = model.modelPath.split("/")[1];
    switch(aId){
        case archId.INCEPTION_V1:{
            const cleanLayer = layerName.split("_")[0];
            const neuronImageDir = process.env.PUBLIC_URL+"layer_neuron_images/";
            return neuronImageDir + cleanLayer + ".jpg";
        }
        case archId.RESNET_18:{
            const neuronImageDir = process.env.PUBLIC_URL+"layer_neuron_images_resnet/"+modelPath+"/";
            const origLayer = originalResnet18Layer2Layer[layerName];
            return neuronImageDir + getResnetLayerName(origLayer) + ".jpg";
        }
    }
}

export function getLayerReceptiveField (rfDict, layerName) {
    for(let [key, value] of Object.entries(rfDict)) {
        if(layerName.includes(key)) {
            return value;
        }
    }
    return 224;
}

export function getReceptiveFieldDict(aId) {
    switch(aId) {
        case archId.INCEPTION_V1:
            return inceptionV1receptiveFieldDict;
        case archId.RESNET_18:
            return resnet18receptiveFieldDict;
    }
}

export function getDefaultActivationParams(aId){
    switch(aId) {
        case archId.INCEPTION_V1:
            return [
                {
                    archId: archId.INCEPTION_V1, layer: 'mixed4a_pre_relu', neurons: [308, 222, 234, 325],
                    showAllNeurons: true, normalize: 0, poolWidth: 1
                }];
        case archId.RESNET_18:
            return [
                {
                    archId: archId.RESNET_18, layer: 'bn3b_branch2b', neurons: [450, 437, 204, 256],
                    showAllNeurons: true, normalize: 0, poolWidth: 1
                }];
    }
} 

export function getDefaultNewActivationParams (aId) {
    switch(aId) {
        case archId.INCEPTION_V1:
            return { archId: archId.INCEPTION_V1, layer: 'conv2d0', neurons: [0, 1], normalize: 0, poolWidth: 1 } ;
        case archId.RESNET_18:
            return { archId: archId.RESNET_18, layer: 'bn0a_branch2a', neurons: [0, 1], normalize: 0, poolWidth: 1 } ;
    }
}