export class TuningCurveResult {
    constructor(animation, resolution, activationParams, neurons) {
        this.resolution = resolution;
        this.activationParams = activationParams;
        this.animationParams = {
            "xAxis": animation.xAxis,
        }

        this.neurons = neurons;
        this.inferenceData = {};
    }

    putData(frameNumber, inferenceData) {
        this.inferenceData[frameNumber] = inferenceData;
    }
}