import {Matrix4} from 'three'

export default {
  uniforms: {
    dataTexture: { type: 't', value: null},
    imageTexture: { type: 't', value: null },
    overlayImage: { type: 'b', value: false },
    neurons: { value: 1.0 },
    normalize: { value: 1.0 },
  },
  
    vertexShader: [
			'uniform float viewWidth, viewHeight, squareWidth, margin, channels;',

			'varying vec2 vUV;',

			'void main(void) {',
      '	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
			'	vUV = uv;',
			'}'
    ].join('\n'),
  
    fragmentShader: [
      'uniform sampler2D dataTexture;',
			'uniform sampler2D imageTexture;',
			'uniform int overlayImage;',
      'uniform float neurons;',
      'uniform float normalize;',
			
			'varying vec2 vUV;',
			
			'const vec3 defaultCol = vec3(0.5, 0.5, 0.5);',
			
			'vec3 redBlue(float brightness);',
			
			'void main(void) {',
      '	float activation = texture2D(dataTexture, vec2(vUV.x, 1.0-vUV.y)).r * normalize;',
      '	vec3 imageColor = texture2D(imageTexture, vec2(vUV.x*neurons, vUV.y)).rgb;',
			'vec3 col = redBlue(activation);',
			'if(activation == 1000000.0) {',
      '	col = vec3(1.0);',
			'} else {',
      '		if(overlayImage == 1) {',
      '			col = imageColor * activation;',
			'		} else {',
      '			col = redBlue(activation);',
      '		}',
      '	}',
      '	gl_FragColor = vec4(col, 1.0);',
      '}',
			
			'vec3 redBlue(float brightness) {',
      '	float r,g,b;',
      '	if(brightness > 0.0){',
      '		r = brightness;',
      '	} else {',
      '		b = -brightness;',
      '	}',
      '	return vec3(r,g,b);',
      '}'
    ].join('\n')
  }