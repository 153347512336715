import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        tDiffuse: { type: 't', value: null},
        tOverlay: { type: 't', value: null},
        alpha: {value: 0.0}
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform sampler2D tOverlay;',
        'uniform float alpha;',
        'varying vec2 vUV;',
        
        'void main(void) {',
        '    vec4 col = texture2D(tDiffuse, vUV);',
        '    vec4 overlay = texture2D(tOverlay, vUV);',
            
        '    gl_FragColor = vec4((col*(1.0-alpha)+overlay*alpha).rgb, 1.0);',
        '}'
    ].join('\n')
  }