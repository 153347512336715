import React, { useRef, useMemo, useState } from 'react'
import { Math as THREEMath, MeshStandardMaterial, ShaderMaterial, DoubleSide, TextureLoader } from 'three'
import { useFrame, useLoader, object3D, boxGeometry, meshStandardMaterial } from 'react-three-fiber'
import {loadDefaultModel, loadCustomModel, setApplicablePropUniforms, defaultDiffuseURL, defaultMaskURL} from './ObjectUtil'

export function ObjectMesh(props) {
    const {uniforms, diffusePath, alphaPath, customObj} = props;
    const [obj, setObj] = useState(null);
    const [material, setMaterial] = useState(null);
    const [position, setPosition] = useState([.02,-0.6,-0.5]);

    const loader = useMemo(() => {
        const callback = (loadedObj, loadedMat) => {
            setObj(loadedObj);
            setMaterial(loadedMat);
        }
        if(customObj) {
            loadCustomModel(customObj.obj, callback);
            setPosition(customObj.position);
        } else {
            loadDefaultModel(callback);
        }
        return null;
    }, [customObj]);

    const diffuseTexture = useLoader(TextureLoader, diffusePath ? diffusePath : defaultDiffuseURL);
    const maskTexture = useLoader(TextureLoader, alphaPath ? alphaPath : defaultMaskURL);

    const mesh = useRef();
    const dummy = useRef();

    useFrame(() => {
        if(!mesh.current) {
            console.log("no model in objectmesh")
            return;
        } else {
            mesh.current.children[0].geometry.attributes.position.needsUpdate = true
        }
    });

    if(obj){
        if(material) {
            if(!customObj) {
                material.uniforms.colorMap.value = diffuseTexture;
                material.uniforms.alphaMap.value = maskTexture;
            }
            if(!material.uniforms.alphaMap.value) {
                    material.transparent = false;
            }
        }
        setApplicablePropUniforms(material, uniforms);
        return <primitive object={obj} ref={mesh} position={position} />
    } else {
        return <mesh
        ref={dummy}>
        <boxBufferGeometry attach="geometry" args={[.1, .1, .1]} />
        <meshStandardMaterial attach="material"/>
      </mesh>
    }
}