import React, { useRef, useEffect, useMemo } from 'react'
import { extend, useThree, useFrame } from 'react-three-fiber'
import {DataTexture, UnsignedByteType, RGBAFormat} from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { AlphaShader,  OverlayShader} from './Shaders'

extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass, ShaderPass })

export function Effects(props) {
    const {effectParams, overlayImageData} = props;
    const composer = useRef()
    const alphaPass = useRef();
    const overlayPass = useRef();
    const { scene, gl, size, camera } = useThree()
    useEffect(() => void composer.current.setSize(size.width, size.height), [size]);
    let overlayTexture = useMemo(() => {
        if(overlayImageData) {
            const t = new DataTexture(
                overlayImageData.data, overlayImageData.w, overlayImageData.h,
                RGBAFormat, UnsignedByteType);
            t.needsUpdate=true;
            t.flipY = true;
            return t;
        } else {
            const t = new DataTexture(
                new Uint8Array([255,255,255,255]), 1, 1,
                RGBAFormat, UnsignedByteType);
            t.needsUpdate=true;
            return t;
        }
    }, [overlayImageData]);

    useFrame(() => {
        if (alphaPass.current) {
            alphaPass.current.uniforms.alpha.value = effectParams.alpha;
        }
        if(overlayPass.current) {
            overlayPass.current.uniforms.tOverlay.value = overlayTexture;
            if(overlayImageData) {
                overlayPass.current.uniforms.alpha.value = 1.0;
            } else {
                overlayPass.current.uniforms.alpha.value = 0.0;
            }
        }
        composer.current.render()
    }, 2);
    return (
        <effectComposer ref={composer} args={[gl]}>
            <renderPass attachArray="passes" scene={scene} camera={camera} />
            {/*<unrealBloomPass attachArray="passes" args={[undefined, effectParams.bloom*4.0, effectParams.bloom*1.5, 0.55]} />*/}
            <shaderPass ref={alphaPass} attachArray="passes" args={[AlphaShader]} />
            <shaderPass ref={overlayPass} attachArray="passes" args={[OverlayShader]} />
        </effectComposer>
    )
}
