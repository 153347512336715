import React, { useRef, useMemo, useState } from 'react'
import { Math as THREEMath, MeshStandardMaterial, ShaderMaterial, DoubleSide, TextureLoader, UniformsUtils, DataTexture} from 'three'
import { CustomBlending, AddEquation, SrcAlphaFactor, LessEqualDepth, dataTexture, LuminanceFormat, FloatType} from 'three'
import { useFrame, useLoader,  } from 'react-three-fiber'
import {ActivationShader} from './Shaders'


function getActivationMaterial() {
    const material = new ShaderMaterial({
        uniforms: UniformsUtils.clone(ActivationShader.uniforms),
        vertexShader: ActivationShader.vertexShader,
        fragmentShader: ActivationShader.fragmentShader,
    });
    return material;
}

export function ActivationPlane(props) {
    const {w, x, activation, params} = props;
    const {data, shape, normalize} = activation;
    const dataLength = data.length;//shape.w*params.neurons.length*shape.h;
    const textureData = useMemo(() => new Float32Array(dataLength), [dataLength]);
    textureData.set(data);
    const activationTexture = useMemo(() => new DataTexture(
        textureData, shape.w*params.neurons.length, shape.h, LuminanceFormat, FloatType), [textureData]);
    activationTexture.needsUpdate = true;
    const material = useMemo(() => getActivationMaterial(), []);
    const mesh = useRef();
    useFrame(() => {
        mesh.current.position.set(x, 0, 0);
        mesh.current.material = material;
        material.uniforms.dataTexture.value = activationTexture;
        material.uniforms.dataTexture.value.needsUpdate = true;
        material.uniforms.neurons.value = params.neurons.length;
        material.uniforms.normalize.value = normalize ? normalize/4 : Math.pow(10, -params.normalize);
    });
    return (
        <mesh 
            ref={mesh} >
            <planeBufferGeometry attach="geometry" args={[w*1, 1, 1]} />
        </mesh>
    );
}