//import * as tf from '@tensorflow/tfjs';
import {tf} from '../TFJS'
import {OutputManager} from './OutputManager'
import {getLayerReceptiveField, getReceptiveFieldDict, archId} from './ModelMetaInfo'

export function getModel(archType, callback) {
    const model = new Model(archType);
    console.log("assigned model")
    model.init(callback);
    return model;
}

function getModelUrl(aId) {
    switch(aId) {
        case archId.INCEPTION_V1:
            return process.env.PUBLIC_URL+'/inceptionv1/model.json';
        case archId.RESNET_18:
            return process.env.PUBLIC_URL+'/Resnet18/model.json';
        default:
            return "";
    }
}

export class Model {

    constructor(archType, modelPath) {
        this.archType = archType;
        this.modelPath = modelPath;
        this.modelName = modelPath.split("/")[modelPath.split("/").length-2];
        this.sourceModel = null;
        this.outputManager = new OutputManager();
        this.receptiveFieldLayer = "";
        this.isLoading = false;
    }

    isLoaded() {
        return this.sourceModel != null;
    }

    async init(callback) {
        if(this.isLoading) {
            console.log("model is still loading");
            return;
        }
        if(!this.isLoaded()) {
            console.log("model not loaded")
            const modelUrl = this.modelPath;
            console.log(modelUrl)
            this.isLoading = true;
            tf.loadLayersModel(
                modelUrl,
                { strict: true }, {onProgress: (fraction) => console.log("loadprogress: "+fraction)}).then(
                    (model) => {this.isLoading = false; this.sourceModel = model; model.summary(); callback();},
                    (reason) => {this.isLoading = false; console.log('rejected because', reason); callback();});
        }
        else {
            console.log("model already loaded")
            callback();
        }
    }

    getInputShape() {
        if(this.sourceModel) {
            return this.sourceModel.inputs[0].shape;
        }
    }

    getLayerNames = () => {
        let layerNames = [];
        if(this.isLoaded()) {
            this.sourceModel.layers.forEach((l) => {
                layerNames.push(l.name);
            })
        }
        console.log("layernames", layerNames)
        return layerNames;
    }

    setReceptiveFieldLayer(layerName) {
        this.receptiveFieldLayer = layerName;
    }

    getReceptiveField() {
        if(this.sourceModel && this.receptiveFieldLayer !== "") {

            const rf = {
                rfWidth: getLayerReceptiveField(
                    getReceptiveFieldDict(this.archType),
                    this.receptiveFieldLayer),
                fmWidth: this.sourceModel.getLayer(this.receptiveFieldLayer).output.shape[2],
                inputWidth: this.sourceModel.input.shape[2]
            }
            return rf;
        } else {
            return undefined;
        }
    }
    
    dispose() {
        this.outputManager.deregisterAll();
        if(this.sourceModel) {
            this.sourceModel.dispose();
            this.sourceModel = null;
        }
    }
}