export class TuningCurvePlot {
    constructor(resolution, neurons, canvasHeight) {
        this.resolution = resolution;
        this.neurons = neurons;

        this.canvasHeight = canvasHeight;
        this.canvasWidth = neurons.length * canvasHeight;
        this.thumbWidth = Math.round(this.canvasHeight / resolution);

        this.canvas = document.createElement("canvas");
        this.canvas.width = this.canvasWidth;
        this.canvas.height = this.canvasHeight;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.width = this.canvasWidth;
        this.ctx.height = this.canvasHeight;

        this.prxCvs = document.createElement('canvas');
        const destWidth = this.canvasWidth / this.resolution / this.neurons.length;
        const destHeight = this.canvasHeight / this.resolution;
        this.prxCvs.width = destWidth;
        this.prxCvs.height = destHeight;
    }

    clearCanvas(){
        this.ctx.fillStyle = 'white';
        this.ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    }

    getImageData() {
        return this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
    }

    putImage(frameNumber, imageData, inferenceData) {
        const std = Math.sqrt(inferenceData.variance);
        const x = frameNumber % this.resolution;
        const y = Math.floor(frameNumber / this.resolution);
        const origWidth = imageData.width, origHeight = imageData.height;
        const prxCvs = document.createElement('canvas');
        const prxCtx = prxCvs.getContext('2d');
        prxCvs.width = origWidth;
        prxCvs.height = origWidth;
        const prxImageData = prxCtx.getImageData(0, 0, origWidth, origWidth);
        let neuronX=0;
        this.neurons.forEach(n => {
            for (let x = 0; x < origWidth; x++) {
                for (let y = 0; y < origHeight; y++) {
                    const rY = origHeight - 1 - y;
                    const iGL = (rY * origWidth + x) * 4;
                    const iCVS = (y * origWidth + x) * 4;
                    const infrncData = inferenceData.data[n];
                    let mul = 1, shift=0;
                    if(infrncData < 0) {
                        mul = -1;
                        shift=255;
                    }
                    prxImageData.data[iCVS] = shift+mul*imageData.data[iGL];
                    prxImageData.data[iCVS + 1] = shift+mul*imageData.data[iGL + 1];
                    prxImageData.data[iCVS + 2] = shift+mul*imageData.data[iGL + 2];
                    const opacity =                     
                    Math.floor(
                        Math.min(
                            Math.max(
                                Math.abs(infrncData)/std/4*255, 0), 255));
                    prxImageData.data[iCVS + 3] = opacity;
                }
            }
            prxCtx.putImageData(prxImageData, 0, 0);
            const destWidth = this.canvasWidth / this.resolution / this.neurons.length;
            const destHeight = this.canvasHeight / this.resolution;
            this.ctx.drawImage(prxCvs, 0, 0, origWidth, origHeight,
                neuronX +x * destWidth,
                y * destHeight, destWidth, destHeight);
            neuronX += this.canvasWidth / this.neurons.length;
        });
    }

    /* Turned out to be even slower
    putImageNew(frameNumber, imageData, inferenceData) {
        this.inferenceData[frameNumber] = inferenceData;
        const std = Math.sqrt(inferenceData.variance);
        const x = frameNumber % this.resolution;
        const y = Math.floor(frameNumber / this.resolution);

        let neuronX=0;
        for(let n=0; n<this.neurons.length; n++) {

            resizeImgAndDrawToImgData(imageData, this.prxCvs);
            const destWidth = this.canvasWidth / this.resolution / this.neurons.length;
            const destHeight = this.canvasHeight / this.resolution;
            this.ctx.drawImage(this.prxCvs, 0, 0, this.prxCvs.width, this.prxCvs.height,
                neuronX +x * destWidth,
                y * destHeight, destWidth, destHeight);
            neuronX += this.canvasWidth / this.neurons.length;
        }
    }*/
}